import DataTableField, { DataTableColorHandler, DataTableFormatterHandler } from './DataTableField'

/**
 * Creates a new columns in the DataTable of type Badge
 */
export default class DataTableBadgeField extends DataTableField {
  constructor(
    key: string,
    label: string,
    color: string | DataTableColorHandler = '',
    show: boolean = true,
    sortable: boolean = true,
    formatter: null | DataTableFormatterHandler = null,
    tdClass: string = 'align-middle text-center',
  ) {
    super(key, label, 'badge', show, sortable, tdClass, color, formatter)
  }
}
