
import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import moment from 'moment'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'

@Component({
  components: {
    FormInput,
    SelectPicker,
    DatePicker,
  },
})
export default class ReconciliationExport extends ViewModel {
  @Prop({ default: () => [] })
  public selected!: string[]

  public modal = true

  public mode: string = 'period'

  public target = moment()
    .subtract(1, 'month')
    .format('YYYY-MM')

  private get mode_options() {
    let ret = [
      {
        name: 'Reconciliation Month',
        value: 'period',
      },
      {
        name: 'Billing Month',
        value: 'billing',
      },
    ]

    if (this.selected.length > 0) {
      ret.push({
        name: 'Selected Items',
        value: 'selected',
      })
    }

    return ret
  }

  public mounted() {
    if (this.selected.length > 0) {
      this.mode = 'selected'
    } else {
      this.mode = 'period'
    }
  }

  public confirm() {
    if (this.mode === 'selected') {
      ReconciliationMonthly.export(this.selected)
      return
    }
    ReconciliationMonthly.export([this.target], null, this.mode)
  }

  public cancel() {
    this.modal = false
    this.$destroy()
    this.$el.parentNode?.removeChild(this.$el)
  }
}
