import DataTableAction from '@/components/DataTable/DataTableAction'
import DataTableActionField from '@/components/DataTable/DataTableActionField'
import DataTableBadgeField from '@/components/DataTable/DataTableBadgeField'
import DataTableField from '@/components/DataTable/DataTableField'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import WebMessage from '@/models/WebMessage'

export default [
  new DataTableField('checkbox', '', 'checkbox', true, false),
  new DataTableField(
    'period',
    'Month',
    'text',
    true,
    true,
    'align-middle text-center',
    null,
    (value: string) => moment(`${value}01`).format('MMMM, YYYY'),
  ),
  new DataTableField('client_impressions', 'Advertiser Impressions', 'abbreviate'),
  new DataTableField('publisher_impressions', 'Publisher Impressions', 'abbreviate'),
  new DataTableField('revenues', 'Gross Revenue', 'currency', false),
  new DataTableField('net_revenues', 'Net Revenue', 'currency'),
  new DataTableField('cost', 'Cost', 'currency'),
  new DataTableBadgeField('status', 'Status', (item: ReconciliationMonthly) => item.statusColor),
  new DataTableActionField([
    new DataTableAction(
      'Reconcile Media Plans',
      'edit',
      'project-diagram',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('reconciliation', 'edit')) as boolean
      },
      (item: ReconciliationMonthly) => {
        let ref = window.location.href.includes('legacy-dashboard') ? '&ref=LegacyDashboard' : ''
        router.push({
          name: 'reconciliation-view',
          params: { period: item.period },
          query: {
            scope: 'client',
          },
        })
      },
    ),
    new DataTableAction(
      'Reconcile Publishers',
      'edit_publisher',
      'ad',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('reconciliation', 'edit')) as boolean
      },
      (item: ReconciliationMonthly) => {
        let ref = window.location.href.includes('legacy-dashboard') ? '&ref=LegacyDashboard' : ''
        router.push({
          name: 'reconciliation-view',
          params: { period: item.period },
          query: {
            scope: 'publisher',
          },
        })
      },
    ),
    new DataTableAction(
      'Download',
      'download',
      'download',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('reconciliation', 'view')) as boolean
      },
      (item: ReconciliationMonthly) => item.export(),
    ),
    new DataTableAction(
      'Reconcolidate Data',
      'sync',
      'sync',
      () => {
        const { user } = getModule(SystemtModule)
        return (user && user.can('reconciliation', 'edit')) as boolean
      },
      (item: ReconciliationMonthly) =>
        WebMessage.confirm(
          'This process will resync the reconciliation lines with the reports and Media Plans, to prevent data loss a backup of the current reconciliated impressions will be downloaded to your computer. <br /><br /> This process might take a few minutes, you will be notified once the process is complete. Once it is completed please review ALL items as numbers / items might have changed.',
          'Are you sure that you want to run a resync?',
          {
            okTitle: 'Yes, run a resync',
          },
          // @ts-ignore
        ).then((confirmed: boolean) => (confirmed ? item.resync() : false)),
    ),
  ]),
]
