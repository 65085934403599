
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'
import FaqModal from '@/components/FaqModal/FaqModal.vue'
import ReconciliationExport from './components/ReconciliationExport.vue'
import fields from './home_fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    FaqModal,
  },
})
export default class ReconciliationHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public records: number = 0

  public loading: boolean = false

  public ready = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {}

  public orphan_line_items: any[] = []

  public show_orphan_line_items: boolean = false

  public orphan_mode: string = 'line_items'

  public parseString(str: string) {
    return str.replaceAll('_', ' ')
  }

  public statusPercentage(value: number, details: any) {
    let total = 0
    for (let v in details) {
      total += details[v]
    }
    return value / total
  }

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()

    return ReconciliationMonthly.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: 'period',
      order: 'desc',
      query: [...context.filter, ...field_filters],
      mode: 'exclusive',
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  public mounted() {
    this.fields = fields
    this.loadFilters()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'reconciliations',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public viewAdServerLineItem(item: string) {
    let match = /\[GAM: (\d+)\]/gims.exec(item)
    if (match) {
      window.open(
        `https://admanager.google.com/21789751937#delivery/line_item/detail/line_item_id=${match[1]}`,
      )
    } else {
      let match = /\[CIM: (.*)\]/gims.exec(item)
      if (match) {
        const route = this.$router.resolve({ name: 'line-item', params: { id: match[1] } })

        window.open(route.href, '_blank')
      }
    }
  }

  public editAdUnit(id: string) {
    const route = this.$router.resolve({ name: 'InventoryEdit', params: { id } })

    window.open(route.href, '_blank')
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('reconciliations').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'reconciliations',
      data: null,
    })
  }

  private showOrphanDetails(orphan_line_items: any, orphan_mode: string) {
    this.orphan_mode = orphan_mode
    this.orphan_line_items = orphan_line_items
    this.show_orphan_line_items = true
  }

  private exportReconciliation() {
    let Component = Vue.extend(ReconciliationExport)
    let instance = new Component({
      propsData: {
        selected: this.selected,
      },
    })
    instance.$mount()
  }
}
